import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { GithubCodeSearchClient } from '@internal/backstage-plugin-github-code-search';
import React from 'react';
import { TechRadarClient } from './lib/techradar/client';

import { ApiEntity } from '@backstage/catalog-model';
import {
  apiDocsConfigRef,
  defaultDefinitionWidgets,
} from '@backstage/plugin-api-docs';
import { ApiReferenceReact } from '@scalar/api-reference-react';
import '@scalar/api-reference-react/style.css';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      const definitionWidgets = defaultDefinitionWidgets().map(obj => {
        if (obj.type === 'openapi') {
          return {
            ...obj,
            component: (definition: any) => {
              console.log('definition', definition);
              return (
                <ApiReferenceReact
                  configuration={{
                    forceDarkModeState: 'dark',
                    isEditable: false,
                    customCss:
                      '.scalar-app { max-height: 100dvh !important; overflow-y: scroll !important; }',
                    hideDarkModeToggle: true,
                    spec: {
                      content: definition,
                    },
                  }}
                />
              );
            },
          };
        }
        return obj;
      });

      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        },
      };
    },
  }),
  createApiFactory(techRadarApiRef, new TechRadarClient()),
  ScmAuth.createDefaultApiFactory(),
  GithubCodeSearchClient.createDefaultApiFactory(),
];
